body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* body {
    font-family: Arial, sans-serif;
    background-color: #121212;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
  }
   */
  /* .container {
    height: 1000px;
    text-align: center;
    background: #6859c9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.1);
    width: 400px;
  } */
  
  .header h1 {
    font-size: 24px;
    color: #4caf50;
  }
  
  .image-container {
    margin-top: 20px;
  }
  
  .image {
    width: 100%;
    border-radius: 8px;
    border: 2px solid #4caf50;
    box-shadow: 0px 4px 10px rgba(0, 255, 0, 0.2);
  }
  
  .message {
    font-size: 14px;
    margin-top: 10px;
    color: #4caf50;
  }
  
  .loading {
    color: yellow;
  }
@tailwind base;
@tailwind components;
@tailwind utilities;


@keyframes spin-scale {
  0% { transform: rotate(0deg) scale(1); opacity: 0.5; }
  50% { transform: rotate(180deg) scale(1.1); opacity: 1; }
  100% { transform: rotate(360deg) scale(1); opacity: 0.5; }
}

.animate-spin-scale {
  animation: spin-scale 2s ease-in-out infinite;
}

@keyframes fade-in-bounce {
  0% { opacity: 0; transform: translateY(20px) scale(0.95); }
  50% { opacity: 0.5; transform: translateY(10px) scale(1); }
  100% { opacity: 1; transform: translateY(0px) scale(1); }
}

.animate-fade-in-bounce {
  animation: fade-in-bounce 1.5s ease-out forwards;
}





/* Dashboard Quick Action Cards */
.dashboard-card {
  @apply flex flex-col items-center justify-center bg-indigo-500 text-white p-4 rounded-lg shadow-md hover:bg-indigo-600 transition transform hover:scale-105;
}

.icon {
  @apply text-4xl mb-2 drop-shadow-lg;
}
